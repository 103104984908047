<template>
  <section class="proof-of-address">
    
    <h2 class="proof-of-address__title headline-small">
      {{$t('poa.title')}}
    </h2>
    
    <div class="proof-of-address__content body-text-large">
      <p>{{$t('poa.verify_address')}}</p>
      <div class="mb-s24" v-html="formattedAddress" />
      <ul>
        <li>{{$t('poa.required_documents.monthly_bill_statement')}}</li>
        <li>{{$t('poa.required_documents.bank_credit_card_account_statement')}}</li>
        <li>{{$t('poa.required_documents.rental_lease_agreement')}}</li>
        <li>{{$t('poa.required_documents.vehicle_registration')}}</li>
        <li>{{$t('poa.required_documents.copy_of_real_estate_property_title')}}</li>
        <li>{{$t('poa.required_documents.property_tax_bill')}}</li>
        <li>{{$t('poa.required_documents.utility_bill')}}</li>
      </ul>
    </div>
    
    <div class="flex flex-col items-center" @click="handleUploadImage">
      <div class="proof-of-address__image-container" :class="{'--has-image': hasImage}">
        <img
          v-if="hasImage"
          class="proof-of-address__uploaded-image"
          :src="imgSrc"
          alt=" "
        />
      </div>
    </div>
    
    <!-- If image wasn't selected -->
    <div v-if="!isValid">

      <div class="proof-of-address__upload-image">
        <input 
          id="uploadedDocument" 
          ref="uploadedDocument" 
          name="uploadedDocument" 
          data-testId="uploadedDocument" 
          data-lpignore="true" 
          class="hidden" 
          type="file" 
          accept="image/*" 
          capture="camera" 
          test
          @change="handleUploadedDocument" 
        />
        <ButtonV2 
          class="mt-s16"
          version="primary"
          size="medium"
          testId="btn-uploadImage"
          wide
          :label="$t('poa.upload_image')"
          @onClick="handleUploadImage"
        />
      </div>
    </div>
    
    <!-- else image selected -->
    <div v-else class="proof-of-address__submit flex">
      <ButtonV2 
        class="mt-s16 mr-s8"
        version="secondary"
        size="medium"
        wide
        testId="btn-cancel"
        :label="$t('cancel')"
        @onClick="reset"
      />
      <ButtonV2 
        class="mt-s16 ml-s8"
        version="primary"
        testId="btn-poaSubmit"
        size="medium"
        wide
        :label="$t('poa.submit')"
        @onClick="submitDocument"
      />
    </div>

  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { ButtonV2 } from '@/components/misc';
import { markdownToHTML } from '@/utils/markdown';

export default {
  name: 'proof-of-address',
  
  props: {
    address: String,
  },
  
  components: {
    ButtonV2,
  },
  
  data() {
    return {
      uploadedFile: null,
      imgSrc: null,
    };
  },
  
  computed: {
    ...mapGetters('user', ['getKycLevelForAmplitude']),
    hasImage() {
      return !!this.imgSrc;
    },
    isValid() {
      return !!this.proofOfAddressImage;
    },
    proofOfAddressImage() {
      const file = this.uploadedFile;
      if (!file) {return;}
      return URL.createObjectURL(file);
    },
    formattedAddress() {
      const content = this.$t('poa.submit_poa_image', { address: this.address });
      return markdownToHTML(content);
    },
  },

  methods: {
    ...mapActions('user', [
      'loadKycLevel',
      'uploadProofOfAdressFile'
    ]),
    ...mapActions('ui', ['setIsOverlayLoading', 'showPrompt']),

    reset() {
      this.uploadedFile = null;
      this.imgSrc = null;
    },
    handleTakePhoto() {
      this.$refs.photoDocument.click();
    },
    handleUploadImage() {
      this.$refs.uploadedDocument.click();
    },
    handleUploadedDocument() {
      this.uploadedFile = this.$refs.uploadedDocument.files[0];
      
      if(this.uploadedFile) {
        this.imgSrc = URL.createObjectURL(this.uploadedFile);
      }
    },
    async submitDocument() {
      try {
        this.setIsOverlayLoading(true);
        await this.uploadProofOfAdressFile(this.uploadedFile);

        this.showPrompt({
          title: this.$t('poa.success_title'),
          message: this.$t('poa.success_message'),
          type: 'success',
        });

        this.$store.dispatch('events/track', {
          event: 'KYC_UPLOAD_PROOF_OF_ADDRESS_SUBMITTED',
          variables: {
            ...this.getKycLevelForAmplitude,
          },
        });

        this.showModal('KycStatus');
      } catch(e) {
        this.showError(e);
        this.hideModal();
      } finally {
        this.setIsOverlayLoading(false);
      }
    },
  },
  mounted(){
    this.$store.dispatch('events/track', {
      event: 'KYC_UPLOAD_PROOF_OF_ADDRESS_VIEWED',
      variables: {
        ...this.getKycLevelForAmplitude,
      },
    });
  }
};
</script>

<style scoped>
.proof-of-address {
  @apply 
    flex 
    flex-col 
    justify-between 
    self-center
    flex-1
    w-screen 
    max-w-sm 
    p-4;
  max-width: 450px;
}
.proof-of-address__title {
  @apply mb-s24;
}
.proof-of-address__content p {
  @apply mb-s24;
}
.proof-of-address__content ul {
  @apply mb-s24 pl-s16 list-disc;
}
.proof-of-address__content li {
  @apply mb-s2;
}
.proof-of-address__take-photo {
  @apply flex flex-col items-center;
}
.proof-of-address__image-container {
  @apply 
    mb-s24
    p-s8
    flex
    items-center
    justify-center
    relative;
  width: 150px;
  height: 162px;
}
.proof-of-address__image-container img {
  @apply max-w-full;
}
.proof-of-address__image-container::after {
  content: '';
  @apply 
    absolute
    bg-no-repeat
    bg-contain
    bg-center
    w-full
    h-full;
  background-image: url('~@/assets/img/take-picture-initial-light.png');
}
.proof-of-address__image-container.--has-image::after {
  background-image: url('~@/assets/img/take-picture-uploaded-light.png');
}
.dark .proof-of-address__image-container::after {
  background-image: url('~@/assets/img/take-picture-initial-dark.png');
}
.dark .proof-of-address__image-container.--has-image::after {
  background-image: url('~@/assets/img/take-picture-uploaded-dark.png');
}
</style>
